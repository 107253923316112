import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import leftImg from '../Images/left-img.png';
import avatar from '../Images/avatar.png';
import logo from "../Images/igcsmlogo.webp";
import ApiContext from '../Context/ApiContext';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    const apiContext = useContext(ApiContext);
    const { url, loginfunc } = apiContext;

    const handleLogin = async () => {
        try {
            const response = await fetch(`${url}/api/users/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ empId: username, password: password }),
                credentials: 'include'
            });

            const data = await response.json();
            const token = data.token;
            const user = data.user;
            const message = data.message
            const email = data.assets[0]

            if (response.ok) {
                sessionStorage.setItem('token', token);
                loginfunc(token);
                sessionStorage.setItem('name', user.name)
                sessionStorage.setItem('mail', user.empId)
                sessionStorage.setItem('email', email)
                setTimeout(() => {
                    navigate('/auth/home');
                }, 1000);
                setTimeout(()=>{
                    sessionStorage.setItem('noti', 1)
                },2000)
            } else {
                toast.error(message);
            }
        } catch (error) {
            toast.error("Internal Server Error");
        }
    };
    const handleLogin1 = async () => {
        try {
            if(username === "admin" || password === "admin"){
                const token = "token123";
                const user = "admin";
                const name = "admin";
                // const message = "Logged IN Sucessfully"
                const email = "admin@admin.com"
            // }

            // if (response.ok) {
                sessionStorage.setItem('token', token);
                loginfunc(token);
                sessionStorage.setItem('name', name)
                sessionStorage.setItem('mail', email)
                sessionStorage.setItem('email', email)
                setTimeout(() => {
                    navigate('/auth/home');
                }, 1000);
                setTimeout(()=>{
                    sessionStorage.setItem('noti', 1)
                },2000)
            } else {
                toast.error("Invalied Credentials");
            }
        } catch (error) {
            toast.error("Internal Server Error");
        }
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleLogin1(e);
        }
    };

    useEffect(() => {
        const inputs = document.querySelectorAll(".input");

        function addcl() {
            let parent = this.parentNode.parentNode;
            parent.classList.add("focus");
        }

        function remcl() {
            let parent = this.parentNode.parentNode;
            if (this.value === "") {
                parent.classList.remove("focus");
            }
        }

        inputs.forEach(input => {
            input.addEventListener("focus", addcl);
            input.addEventListener("blur", remcl);
        });

        return () => {
            inputs.forEach(input => {
                input.removeEventListener("focus", addcl);
                input.removeEventListener("blur", remcl);
            });
        };
    }, []);

    return (
        <section className="gradient-form">
            <style>{`
                * {
                    padding: 0;
                    margin: 0;
                    box-sizing: border-box;
                }
                body {
                    font-family: 'Poppins', sans-serif;
                    overflow: hidden;
                }
                .wave {
                    position: fixed;
                    bottom: 0;
                    left: 0;
                    height: 100%;
                    z-index: -1;
                }
                .container {
                    width: 100vw;
                    height: 100vh;
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    grid-gap: 7rem;
                    padding: 0 2rem;
                }
                .img {
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                }
                .login-content {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                }
                .img img {
                    width: 500px;
                }
                div.mk {
                    width: 360px;
                }
                .login-content img {
                    height: 100px;
                }
                .login-content h2 {
                    margin: 15px 0;
                    color: #333;
                    text-transform: uppercase;
                    font-size: 2.9rem;
                }
                .login-content .input-div {
                    position: relative;
                    display: grid;
                    grid-template-columns: 7% 93%;
                    margin: 25px 0;
                    padding: 5px 0;
                    border-bottom: 2px solid #d9d9d9;
                }
                .login-content .input-div.one {
                    margin-top: 0;
                }
                .i {
                    color: #d9d9d9;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                .i i {
                    transition: .3s;
                }
                .input-div > div {
                    position: relative;
                    height: 45px;
                }
                .input-div > div > h5 {
                    position: absolute;
                    left: 10px;
                    top: 50%;
                    transform: translateY(-50%);
                    color: #999;
                    font-size: 18px;
                    transition: .3s;
                }
                .input-div:before, .input-div:after {
                    content: '';
                    position: absolute;
                    bottom: -2px;
                    width: 0%;
                    height: 2px;
                    background-color: #f84d4c;
                    transition: .4s;
                }
                .input-div:before {
                    right: 50%;
                }
                .input-div:after {
                    left: 50%;
                }
                .input-div.focus:before, .input-div.focus:after {
                    width: 50%;
                }
                .input-div.focus > div > h5 {
                    top: -5px;
                    font-size: 15px;
                }
                .input-div.focus > .i > i {
                    color: #f84d4c;
                }
                .input-div > div > input {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    border: none;
                    outline: none;
                    background: none;
                    padding: 0.5rem 0.7rem;
                    font-size: 1.2rem;
                    color: #555;
                    font-family: 'Poppins', sans-serif;
                }
                .input-div.pass {
                    margin-bottom: 4px;
                }
                a {
                    display: block;
                    text-align: right;
                    text-decoration: none;
                    color: #999;
                    font-size: 0.9rem;
                    transition: .3s;
                }
                a:hover {
                    color: #f84d4c;
                }
                .btn {
                    display: block;
                    width: 100%;
                    height: 50px;
                    border-radius: 25px;
                    outline: none;
                    border: none;
                    background-image: linear-gradient(to right, red, lightgreen);
                    background-size: 200%;
                    font-size: 1.2rem;
                    color: #fff;
                    font-family: 'Poppins', sans-serif;
                    text-transform: uppercase;
                    margin: 1rem 0;
                    cursor: pointer;
                    transition: .5s;
                }
                .btn:hover {
                    background-position: right;
                }
                img.vert-move {
                    -webkit-animation: mover 1s infinite alternate;
                    animation: mover 1s infinite alternate;
                }
                @-webkit-keyframes mover {
                    0% {
                        transform: translateY(0);
                    }
                    100% {
                        transform: translateY(-10px);
                    }
                }
                @keyframes mover {
                    0% {
                        transform: translateY(0);
                    }
                    100% {
                        transform: translateY(-10px);
                    }
                }
                @media screen and (max-width: 1050px) {
                    .container {
                        grid-gap: 5rem;
                    }
                }
                @media screen and (max-width: 1000px) {
                    div.mk {
                        width: 290px;
                    }
                    .login-content h2 {
                        font-size: 2.4rem;
                        margin: 8px 0;
                    }
                    .img img {
                        width: 400px;
                    }
                }
                @media screen and (max-width: 900px) {
                    .container {
                        grid-template-columns: 1fr;
                    }
                    .img {
                        display: none;
                    }
                    .wave {
                        display: none;
                    }
                    .login-content {
                        justify-content: center;
                    }
                }
            `}</style>
            <div className="container">
                <div className="img">
                    <img src={leftImg} className="vert-move" alt="Left Image" />
                </div>
                <div className="login-content">
                    <ToastContainer />
                    <div className="mk">
                        <img src={avatar} alt="Avatar" />
                        <div className="title mb-4">
                            <img src={logo} alt="bigcrm_logo" style={{ height: "60px", width: "200px" }} />
                        </div>
                        <div className="input-div one">
                            <div className="i">
                                <i className="mdi mdi-account"></i>
                            </div>
                            <div className="div">
                                <h5>Email Id</h5>
                                <input
                                    type="text"
                                    className="input"
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="input-div pass">
                            <div className="i">
                                <i className="mdi mdi-lock"></i>
                            </div>
                            <div className="div">
                                <h5>Password</h5>
                                <input
                                    type="password"
                                    className="input"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    onKeyPress={handleKeyPress}
                                />
                            </div>
                        </div>
                        {/* <a href="/">Forgot Password?</a> */}
                        <input type="submit" onClick={handleLogin1} className="btn" value="Login" />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Login;
