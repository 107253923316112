import { useContext, useMemo } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import ApiContext from "../../Context/ApiContext";
import { mkConfig, generateCsv, download } from "export-to-csv";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { Box, Button } from "@mui/material";

const EnquiryTable = () => {
  const context = useContext(ApiContext);
  const { sgtuData } = context;

  const csvConfig = mkConfig({
    fieldSeparator: ",",
    decimalSeparator: ".",
    useKeysAsHeaders: true,
  });

  const handleExportData = () => {
    const dataToExport = sgtuData.map(
      ({ Edit, View, Status, ...rest }) => rest
    );
    const csv = generateCsv(csvConfig)(dataToExport);
    download(csvConfig)(csv);
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "yourName",
        header: "Name",
        size: 10,
        muiTableBodyCellProps: { align: "center" },
      },
      {
        accessorKey: "phoneNumber",
        header: "Contact Details",
        size: 100,
      },
      {
        accessorKey: "email",
        header: "Email Address",
        size: 20,
      },
      {
        accessorKey: "institutionName",
        header: "Institute Name",
        size: 10,
      },
      {
        accessorKey: "districtName",
        header: "District",
        size: 10,
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    data: sgtuData,
    enableColumnOrdering: true,
    initialState: {
      density: "compact",
    },
    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: "flex",
          gap: "16px",
          padding: "8px",
          flexWrap: "wrap",
        }}
      >
        <Button
          // Export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
          onClick={handleExportData}
          startIcon={<FileDownloadIcon />}
        >
          Export All Data
        </Button>
      </Box>
    ),
  });

  return (
    <div>
      <MaterialReactTable table={table} />;
    </div>
  );
};

export default EnquiryTable;
