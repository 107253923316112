import { useState, useEffect } from "react";
import ApiContext from "./ApiContext";
import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
import axios from 'axios';
import { Button } from '@mui/material';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ApiState = (props) => {
  const navigate = useNavigate();
  const url = "https://api.igcsm.online";

  // const notify = () => toast("Wow so easy!");
  // Handing Login

  const [usertoken, setusertoken] = useState()
  
  const loginfunc = (token)=>{
    // shownoti()
    setusertoken(token)
    fetchData();
  }
  
  const shownoti = ()=>{
    toast.success("LoggedIn Successfully!!");

  }

  const logoutfunc = () => {
    setusertoken(null)
    sessionStorage.removeItem('token')
    sessionStorage.removeItem('noti')
    navigate('/login');
    toast.info("Logged out successfully!");
  };

  // const [totalAsset, setTotalasset] = useState('loading');
  // const [user, setuser] = useState('loading');
  // const [runniAsset, setrunniasset] = useState('loading');
  // const [notrunniAsset, setnotrunniasset] = useState('loading');
  // const [userData, setUserData] = useState([]);
  // const [assetData, setAssetData] = useState([]);
  // const [analyticsData, setAnalyticsData] = useState([]);
  // const [moniteringData, setMoniteringData] = useState([]);
  // const [textFormData, setTextformData] = useState(null);
  // const [pendingTicket, setPendingTicket] = useState('loading')
  // const [openTicket, setOpenTicket] = useState('loading')

  // const countTotalAsset = () => {
  //   fetch(`${url}/api/assets/count`,{
  //     headers:{
  //       'content-type': 'application/json',
  //       'token': sessionStorage.getItem('token')
  //     }
  //   })
  //     .then((response) => response.json())
  //     .then((data) => {
  //       setTotalasset(data.count);
  //       return data;
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching the asset count:", error);
  //     });
  // };

  // const countPendingTicket = ()=>{
  //   fetch(`${url}/api/tickets/count/pendingTicket`,{
  //     // method: 'POST',
  //     headers: {
  //         'Content-Type': 'application/json',
  //         'token': sessionStorage.getItem('token')
  //     }
  // })
  //     .then((response) => response.json())
  //     .then((data) => {
  //       setPendingTicket(data.pendingTicketsCount);
  //       return data;
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching the asset count:", error);
  //     });
  // }
  // const countOpenTicket = ()=>{
  //   fetch(`${url}/api/tickets/count/openTicket`,{
  //     headers:{
  //       'content-type': 'application/json',
  //       'token': sessionStorage.getItem('token')
  //     }
  //   })
  //     .then((response) => response.json())
  //     .then((data) => {
  //       setOpenTicket(data.openTicketsCount);
  //       return data;
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching the asset count:", error);
  //     });
  // }

  // const countalluser = () => {

  //   fetch(`${url}/api/users/count`,{
  //     headers:{
  //       'content-type': 'application/json',
  //       'token': sessionStorage.getItem('token')
  //     }
  //   })
  //     .then((response) => response.json())
  //     .then((data) => {
  //       setuser(data.count);
  //       return data;
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching the user count:", error);
  //     });
  // };

  // const countrunniAsset = () => {
  //   fetch(`${url}/api/assets/runningAssets`,{
  //     headers:{
  //       'content-type': 'application/json',
  //       'token': sessionStorage.getItem('token')
  //     }
  //   })
  //     .then((response) => response.json())
  //     .then((data) => {
  //       setrunniasset(data.runningAssetsCount);
  //       return data;
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching the asset count:", error);
  //     });
  // };

  // const countnotrunniAsset = () => {
  //   fetch(`${url}/api/assets/unreachableAssets/count`,{
  //     headers:{
  //       'content-type': 'application/json',
  //       'token': sessionStorage.getItem('token')
  //     }
  //   })
  //     .then((response) => response.json())
  //     .then((data) => {
  //       setnotrunniasset(data.unreachableAssetsCount);
  //       return data;
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching the asset count:", error);
  //     });
  // };
  // const [editUserID, setEditUserId] = useState();

  // const handleEdit = (userId) => {
  //   setEditUserId(userId);
  //   fetch(`${url}/api/users/${userId}`,{
  //     headers:{
  //       'content-type': 'application/json',
  //       'token': sessionStorage.getItem('token')
  //     }
  //   })
  //     .then((response) => response.json())
  //     .then((data) => {
  //       setEditUserId(data); // Store fetched user data in state
  //       navigate("/auth/edituser"); // Navigate to EditUser component
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching user data:", error);
  //     });
  // };

  // const [editAssetId, setEditAssetid] = useState();
  // const [editAssetData, setEditAssetData] = useState();

  // const assetEdit = (assetId) => {
  //   console.log(assetId);
  //   setEditAssetid(assetId);
  //   fetch(`${url}/api/assets/${assetId}`,{
  //     headers:{
  //       'content-type': 'application/json',
  //       'token': sessionStorage.getItem('token')
  //     }
  //   })
  //     .then((response) => response.json())
  //     .then((data) => {
  //       setEditAssetData(data);
  //       navigate("/auth/editassets");
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching the asset data", error);
  //     });
  // }
  // // const [viewAssetId, setviewAssetid] = useState();
  // const [viewAssetData, setviewAssetData] = useState();

  // const viewAsset = (assetId) => {
  //   console.log(assetId);
  //   // setviewAssetid(assetId);
  //   fetch(`${url}/api/assets/${assetId}`,{
  //     headers:{
  //       'content-type': 'application/json',
  //       'token': sessionStorage.getItem('token')
  //     }
  //   })
  //     .then((response) => response.json())
  //     .then((data) => {
  //       setviewAssetData(data);
  //       navigate("/auth/viewAsset");
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching the asset data", error);
  //     });
  // };

  const [ticketData, setTicketData] = useState([]);


  const fetchTicketData = async () => {
    try {
      const response = await axios.get(`${url}/api/contact`,{
        headers:{
          'content-type': 'application/json',
          'token': sessionStorage.getItem('token')
        }
      });
      const transformedTicketData = response.data.map((item) => ({
        inquiryNo: item.inquiryNo,
        fullName: item.fullName,
        emailAddress: item.emailAddress,
        phoneNumber: item.phoneNumber,
        subject: item.subject,
        message: item.message,
        address: item.address,
        collegeName: item.collegeName,
      }));
      setTicketData(transformedTicketData);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };


  const [sgtuData, setsgtuData] = useState([]);


  const fetchsgtuData = async () => {
    try {
      const response = await axios.get(`${url}/api/sgtu`, {
        headers: {
          "content-type": "application/json",
          token: sessionStorage.getItem("token"),
        },
      });
      const transformedsgtuData = response.data.map((item) => ({
        yourName: item.yourName,
        phoneNumber: item.phoneNumber,
        email: item.email,
        institutionName: item.institutionName,
        districtName: item.districtName,
      }));
      setsgtuData(transformedsgtuData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };


  const [igcsmData, setigcsmData] = useState([]);


  const fetchigcsmData = async () => {
    try {
      const response = await axios.get(`${url}/api/franchisee`, {
        headers: {
          "content-type": "application/json",
          token: sessionStorage.getItem("token"),
        },
      });
      const transformedigcsmData = response.data.map((item) => ({
        yourName: item.yourName,
        phoneNumber: item.phoneNumber,
        email: item.email,
        institutionName: item.institutionName,
        districtName: item.districtName,
      }));
      setigcsmData(transformedigcsmData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // const fetchMoniteringData = async () => {
  //   try {
  //     const response = await axios.get(`${url}/api/assets/unreachableAssets`,{
  //       headers:{
  //         'content-type': 'application/json',
  //         'token': sessionStorage.getItem('token')
  //       }
  //     });
  //     const transformedMoniteringData = response.data.map(item => ({
  //       Link_ID: item.linkId,
  //       Site_Name: item.siteName,
  //       IP: item.ipAddress1,
  //       Down_for: item.DownFor,
  //       Status: item.LiveStatus,
  //       Connectivity: item.connectivity,
  //       Link_BW: item.linkBW,
  //       Auto_Ticket: 'N/A', // You might need to calculate or get this value from your data
  //       last_status: item.TicketStatus, 
  //       Edit: <button className='tableBtn'>Edit</button>,
  //       View: <button className='tableBtn'>View</button>,
  //     }));
  //     setMoniteringData(transformedMoniteringData);
  //   } catch (error) {
  //     console.error('Error fetching data:', error);
  //   }
  // };

  // const fetchAnalyticsData = async () => {
  //   try {
  //     const response = await axios.get(`${url}/api/assets/analytics`,{
  //       headers:{
  //         'content-type': 'application/json',
  //         'token': sessionStorage.getItem('token')
  //       }
  //     });
  //     const transformedData = response.data.map(item => ({
  //       Link_ID: item.linkId,
  //       Device_Name: item.siteName,
  //       IP_Adress: item.ipAddress1,
  //       Live_Status: item.liveStatus,
  //       Performance: item.Performance,
  //       Network_Chart: "Chart",
  //       Connectivity: item.connectivity,
  //       Packet: item.Packet
  //     }));
  //     setAnalyticsData(transformedData);
  //   } catch (error) {
  //     console.error('Error fetching analytics data:', error);
  //   }
  // };

  // const fetchAssetData = async () =>{
  //   try {
  //     const response = await axios.get(`${url}/api/assets`,{
  //       headers:{
  //         'content-type': 'application/json',
  //         'token': sessionStorage.getItem('token')
  //       }
  //     })
  //     const transformedassetData = response.data.map((asset, index) => ({
  //       id: asset._id,
  //       Serial_Number: index + 1,
  //       Link_ID: asset.linkId,
  //       Site_Name: asset.siteName,
  //       Connectivity: asset.connectivity,
  //       Link_BW: asset.linkBW,
  //       IP: asset.ipAddress1,
  //       Status: asset.status,
  //       Auto_Ticket: asset.Auto_Ticket === 'Yes' ? 'Yes' : 'No',
  //       Edit: (
  //         <Button
  //           variant="contained"
  //           sx={{ margin: '1px', padding: '1px ', backgroundColor: 'rgb(244,245,247)', color: 'black', boxShadow: 'none', border: '1px solid grey', opacity: '0.5' }}
  //           onClick={() => assetEdit(asset.linkId)}
  //         >
  //           Edit
  //         </Button>
  //       ),
  //       View: (
  //         <Button
  //           variant="contained"
  //           sx={{ margin: '1px', padding: '1px ', backgroundColor: 'rgb(244,245,247)', color: 'black', boxShadow: 'none', border: '1px solid grey', opacity: '0.5'  }}
  //           onClick={() => viewAsset(asset.linkId)}
  //         >
  //           View
  //         </Button>
  //       ),
  //     }))
  //     setAssetData(transformedassetData)
  //   } catch (error) {
  //     console.log("Error fetching the asset" + error);
  //   }
  // }

  // const handleStatusChange = async (linkId, newStatus) => {
  //   try {
  //     const response = await fetch(`${url}/api/assets/updateStatus`, {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //         'token': sessionStorage.getItem('token'),
  //       },
  //       body: JSON.stringify({ linkId, status: newStatus }),
  //     });

  //     if (response.ok) {
  //       const updatedData = assetData.map((asset) =>
  //         asset.Link_ID === linkId ? { ...asset, Status: newStatus } : asset
  //       );
  //       setAssetData(updatedData);
  //       fetchMoniteringData()
  //     } else {
  //       console.error('Failed to update status');
  //     }
  //   } catch (error) {
  //     console.error('Error updating status:', error);
  //   }
  // };

  // const userStatusChange = async (userId, newStatus)=>{
  //   try {
  //   const response = await fetch(`${url}/api/users/Userstatus/${userId}`,{
  //     method: 'PUT',
  //       headers: {
  //         'Content-Type': 'application/json',
  //         'token': sessionStorage.getItem('token'),
  //       },
  //     body: JSON.stringify({status: newStatus})
  //   });
  //   if(response.ok){
  //     alluserData()
  //     Swal.fire({
  //       title: 'Success!',
  //       text: 'Status Updation Success',
  //       icon: 'success',
  //     });
  //   }
  //   } catch (error) {
  //     console.log(error + "Error Updating the status")
  //   }
  // }

  // const bulkStatusChange = (status) => {
  //   assetData.forEach(asset => {
  //     handleStatusChange(asset.Link_ID, status);
  //   });
  //   Swal.fire({
  //     title: 'Success!',
  //     text: 'Status Updation Success',
  //     icon: 'success',
  //   });
  //   fetchMoniteringData()
  //   setTimeout(() => {
  //     fetchAssetData()
  //   }, 1000);
  // };
  
  // const [ticketData, setTicketData] = useState([])

  // const fetchTicketData = ()=>{
  //   fetch(`${url}/api/tickets`,{
  //     headers:{
  //       'content-type': 'application/json',
  //       'token': sessionStorage.getItem('token')
  //     }
  //   })
  //     .then((response) => response.json())
  //     .then((data) => {
  //       const formattedData = data.map((asset, index) => ({
  //         TicketNo: asset.TicketNo,
  //         Site_Name: asset.SiteName,
  //         Link_ID: asset.LinkId,
  //         ProblemCode: asset.ProblemCode,
  //         Status: asset.Status,
  //         Sr_Close_Time: asset.SrCloser_Timer,
  //         Up_Timer: asset.Up_Timer,
  //         Down_Timer: asset.Down_Timer,
  //         RFO: asset.RFO,
  //         Assiged_By: asset.AssignedBy,
  //         Created_By: asset.CreatedBy,
  //         Created_Date: asset.CreatedDate,
  //         Last_Updated_By: asset.LastUpdateBy,
  //         Last_Update_Time: asset.LastUpdateDate,
  //         Serial_no: asset.SrNo,
  //       }));
  //       setTicketData(formattedData);
  //     })
  //     .catch((error) => {
  //       console.error('Error fetching data:' + error);
  //     });
  // }

  // const alluserData = ()=>{
  //   fetch(`${url}/api/users`,{
  //     headers:{
  //       'content-type': 'application/json',
  //       'token': sessionStorage.getItem('token')
  //     }
  //   })
  //     .then(response => response.json())
  //     .then(data => {
  //       // Extract required fields and format data
  //       const formattedData = data.map((user, index) => ({
  //         id: user._id, // Add user ID
  //         Serial_Number: index + 1,
  //         Name: user.name,
  //         Mobile: user.mobileNo,
  //         Emp_Id: user.empId,
  //         User_Type: user.userRole,
  //         Live_Location: user.address,
  //         Edit: <Button variant="contained" sx={{ margin: '1px', padding: '1px ', backgroundColor: 'rgb(244,245,247)', color: 'black', boxShadow: 'none', border: '1px solid grey', opacity: '0.5' }} onClick={() => handleEdit(user._id)}>Edit</Button>, 
  //         Status: user.status 
  //       }));
  //       // Set formatted data to state
  //       setUserData(formattedData);
  //     })
  //     .catch(error => {
  //       console.error('Error fetching data:', error);
  //     });
  // }

  
  // const [assignedUsers, setAssignedUsers] = useState([]);

  // const findUserName = ()=>{
  //   fetch(`${url}/api/users`,{
  //     headers:{
  //       'content-type': 'application/json',
  //       'token': sessionStorage.getItem('token')
  //     }
  //   })
  //     .then((response) => response.json())
  //     .then((data) => {
  //       // Extract required fields and format data
  //       const assignedUserData = data.map((user, index) => ({
  //         Name: user.name,
  //       }));
  //       // Set formatted data to state
  //       setAssignedUsers(assignedUserData);
  //       // console.log(assignedUserData);
  //     })
  //     .catch((error) => {
  //       console.error('Error fetching data:', error);
  //     });
  // }

  // const [viewTicketData, setViewTicketData] = useState([])

  // const viewTicket = async(tno)=>{
  //   const response = await fetch(`${url}/api/tickets/${tno}`,{
  //     headers:{
  //       'content-type': 'application/json',
  //       'token': sessionStorage.getItem('token')
  //     }
  //   })
  //   .then((response)=> response.json())
  //   .then((data)=>{
  //     setViewTicketData(data)
  //   })
  //   navigate('/auth/updateticket')

  // }

  // const [ramdata, setramData] = useState([
  //   { name: 'Unused RAM', value: 100 },
  //   { name: 'Used RAM', value: 100 },
  // ]);

  // const [cpudata, setCpudata] = useState([ 
  //   { name: 'Used CPU', value: 700 },
  //   { name: 'Unused CPU', value: 300 },
  // ]);

  // const [ram, setRam] = useState(null);
  // const [cpu, setCpu] = useState(null)

  // const FetchRamUsage = async () => {
  //   try {
  //     const response = await fetch(`${url}/api/assets/ram/usage`);
      
  //     if (!response.ok) {
  //       throw new Error(`HTTP error! status: ${response.status}`);
  //     }

  //     const result = await response.json();
  //     const ramUsageString = result.ramUsage;
  //     const usedRam = parseFloat(ramUsageString.replace('%', ''));
  //     const unusedRam = 100 - usedRam;
  //     setramData([
  //       { name: 'Unused RAM', value: unusedRam },
  //       { name: 'Used RAM', value: usedRam }, 
  //     ]);
  //     setRam(usedRam);
  //     // console.log(usedRam, unusedRam)
  //   } catch (error) {
  //     console.error('Error fetching RAM usage:', error);
  //   }
  // };

  // const [enoti, setEnoti] = useState(true)
  // const EmailNotification = async(status)=>{
  //   try {
  //     const response = await fetch(`${url}/api/assets/updateAllEmailNotifications`,{
  //       method: 'POST',
  //         headers: {
  //           'Content-Type': 'application/json',
  //           'token': sessionStorage.getItem('token'),
  //         },
  //       body: JSON.stringify({emailNotifications: status})
  //     });
  //     const data = await response.json()
  //     const {message} = data
  //     toast.success(message)

  //   } catch (error) {
  //     console.log('Internal Server Error')
  //   }
  // }

  // const FetchCPUUsage = async () => {
  //   try {
  //     const response = await fetch(`${url}/api/assets/cpu/usage`);
      
  //     if (!response.ok) {
  //       throw new Error(`HTTP error! status: ${response.status}`);
  //     }

  //     const result = await response.json();
  //     const CpuUsageString = result.cpuUsage;
  //     const usedCpu = parseFloat(CpuUsageString.replace('%', ''));
  //     const unusedCpu = 100 - usedCpu;
  //     setCpudata([
  //       { name: 'Unused CPU', value: unusedCpu },
  //       { name: 'Used CPU', value: usedCpu }, 
  //     ]);
  //     setCpu(usedCpu);
  //     // console.log(usedRam, unusedRam)
  //   } catch (error) {
  //     console.error('Error fetching cpu usage:', error);
  //   }
  // };

//   let inactivityTime = function () {
//     let time;

//     const resetTimer = function () {
//         clearTimeout(time);
//         time = setTimeout(logoutfunc, 5000);  // 5 minutes in milliseconds
//     };

//     // Events to reset the timer
//     window.onload = resetTimer;
//     window.onmousemove = resetTimer;
//     window.onmousedown = resetTimer;  // Catches touchscreen presses as well
//     window.ontouchstart = resetTimer; // Catches touchscreen swipes as well
//     window.ontouchmove = resetTimer;  // Catches touchscreen swipes as well
//     window.onclick = resetTimer;      // Catches touchpad clicks as well
//     window.onkeypress = resetTimer;
//     window.addEventListener('scroll', resetTimer, true); // improved; see comments

//     resetTimer();  // Initial call to set up the timer
// };

// inactivityTime();

  const fetchData = () => {
    fetchTicketData();
    fetchigcsmData();
    fetchsgtuData();
    // countTotalAsset();
    // countrunniAsset();
    // countnotrunniAsset();
    // countalluser();
    // alluserData();
    // fetchMoniteringData();
    // fetchAnalyticsData();
    // fetchAssetData();
    // fetchTicketData();
    // findUserName();
    // countPendingTicket();
    // countOpenTicket();
    // FetchRamUsage();
    // FetchCPUUsage();
  };

  useEffect(() => {
    fetchData();
      const intervalId = setInterval(()=>{
        if(usertoken){
          fetchData()
        }
      }, 10000);
      return () => clearInterval(intervalId); 
  }, []);

  return (
    <ApiContext.Provider
      value={{
        url,
        // userData,
        // countTotalAsset,
        // countnotrunniAsset,
        // notrunniAsset,
        // runniAsset,
        // countrunniAsset,
        // setUserData,
        // handleEdit,
        // setEditUserId,
        // editUserID,
        // setAssetData,
        // assetData,
        // editAssetId,
        // setEditAssetData,
        // assetEdit,
        // totalAsset,
        // countalluser,
        // user,
        // viewAsset,
        // viewAssetData,
        // editAssetData,
        // moniteringData,
        // analyticsData,
        // fetchAssetData,
        // ticketData, 
        // assignedUsers,
        // viewTicketData,
        // viewTicket,
        // textFormData, 
        // setTextformData,
        // pendingTicket,
        // openTicket,
        // fetchData,
        // alluserData,
        // ram,
        // ramdata,
        // cpudata,
        // cpu,
        loginfunc,
        logoutfunc,
        ticketData,
        igcsmData,
        sgtuData,
        // bulkStatusChange,
        // handleStatusChange,
        shownoti,
        // userStatusChange,
        // EmailNotification,
        // enoti, setEnoti
      }}
    >
      {props.children}
    </ApiContext.Provider>
  );
};

export default ApiState;
