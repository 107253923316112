import React, { useEffect, useContext } from "react";
import logo from "../Images/igcsmlogo.webp";
import { useNavigate } from 'react-router-dom';
import userimg from '../Images/avatar.png';
import ApiContext from '../Context/ApiContext';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';

const NavBar = () => {
  const navigate = useNavigate();
  const { shownoti, logoutfunc} = useContext(ApiContext);

  const handlepin = () => {
    navigate('/auth/ping');
  };

  const currentDate = new Date();
  const month = currentDate.getMonth() + 1; 
  const day = currentDate.getDate();
  const year = currentDate.getFullYear();
  const fDate = `${month}/${day}/${year}`;
  let name = sessionStorage.getItem('name')
  let mail = sessionStorage.getItem('mail')
    useEffect(() => {
    const noti = sessionStorage.getItem('noti')
    // setTimeout(() => {
      if(noti != 1){
        shownoti()
      }
    // }, 500);

    const toggleButton = document.querySelector('[data-bs-toggle="minimize"]');
    const body = document.body;

    const toggleSidebar = () => {
      if (body.classList.contains('sidebar-toggle-display') || body.classList.contains('sidebar-absolute')) {
        body.classList.toggle('sidebar-hidden');
      } else {
        body.classList.toggle('sidebar-icon-only');
      }
    };

    toggleButton.addEventListener("click", toggleSidebar);

    return () => {
      toggleButton.removeEventListener("click", toggleSidebar);
    };
  }, []);

  return (
    <nav className="navbar admin col-lg-12 col-12 p-0 fixed-top d-flex align-items-top flex-row">
      <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-start">
        <div className="me-3">
          <button
            className="navbar-toggler navbar-toggler align-self-center"
            type="button"
            data-bs-toggle="minimize"
          >
            <span className="icon-menu"></span>
          </button>
        </div>
        <div>
          <a className="navbar-brand brand-logo">
            <img src={logo} alt="logo" />
          </a>
          <a className="navbar-brand brand-logo-mini" href="index.html"></a>
        </div>
      </div>
      <div className="navbar-menu-wrapper d-flex align-items-top">
        <ul className="navbar-nav">
          <li className="nav-item font-weight-semibold d-none d-lg-block ms-0">
            <h1 className="welcome-text">
              Welcome, <span className="text-black fw-bold">{name}</span>
            </h1>
            <h3 className="welcome-sub-text">
              INDIRA GANDHI COMPUTER SHAKSHARTA MISSION
            </h3>
          </li>
        </ul>
        <ul className="navbar-nav ms-auto">
          {/* <li className="nav-item">
            <button
              className="px-2 py- text-white"
              style={{ background: "black", fontWeight: "bold", borderRadius: "8px" }}
              onClick={handlepin}
            >
              PIN
            </button>
          </li> */}
          <li className="nav-item d-none d-lg-block">
            <div
              id="datepicker-popup"
              className="input-group date datepicker navbar-date-picker"
            >
              <span className="input-group-addon input-group-prepend border-right">
                <span className="icon-calendar input-group-text calendar-icon"></span>
              </span>
              <input
                type="text"
                className="form-control fdate"
                style={{ color: "black" }}
                placeholder={fDate}
              />
            </div>
          </li>
          <li className="nav-item">
            <form className="search-form" action="#">
              <i className="icon-search"></i>
              <input
                type="search"
                className="form-control"
                placeholder="Search Here"
                title="Search here"
              />
            </form>
          </li>
          <li className="nav-item dropdown">
            <a
              className="nav-link count-indicator"
              id="notificationDropdown"
              href="#"
              data-bs-toggle="dropdown"
            >
              <i className="icon-mail icon-lg"></i>
            </a>
            <div
              className="dropdown-menu dropdown-menu-right navbar-dropdown preview-list pb-0"
              aria-labelledby="notificationDropdown"
            >
              <a className="dropdown-item py-3 border-bottom">
                <p className="mb-0 font-weight-medium float-left">
                  You have 4 new notifications
                </p>
                <span className="badge badge-pill badge-primary float-right">
                  View all
                </span>
              </a>
              <a className="dropdown-item preview-item py-3">
                <div className="preview-thumbnail">
                  <i className="mdi mdi-alert m-auto text-primary"></i>
                </div>
                <div className="preview-item-content">
                  <h6 className="preview-subject fw-normal text-dark mb-1">
                    Application Error
                  </h6>
                  <p className="fw-light small-text mb-0"> Just now </p>
                </div>
              </a>
              <a className="dropdown-item preview-item py-3">
                <div className="preview-thumbnail">
                  <i className="mdi mdi-settings m-auto text-primary"></i>
                </div>
                <div className="preview-item-content">
                  <h6 className="preview-subject fw-normal text-dark mb-1">
                    Settings
                  </h6>
                  <p className="fw-light small-text mb-0"> Private message </p>
                </div>
              </a>
              <a className="dropdown-item preview-item py-3">
                <div className="preview-thumbnail">
                  <i className="mdi mdi-airballoon m-auto text-primary"></i>
                </div>
                <div className="preview-item-content">
                  <h6 className="preview-subject fw-normal text-dark mb-1">
                    New user registration
                  </h6>
                  <p className="fw-light small-text mb-0"> 2 days ago </p>
                </div>
              </a>
            </div>
          </li>
          <li className="nav-item dropdown">
            <a
              className="nav-link count-indicator"
              id="countDropdown"
              href="#"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i className="icon-bell"></i>
              <span className="count"></span>
            </a>
            <div
              className="dropdown-menu dropdown-menu-right navbar-dropdown preview-list pb-0"
              aria-labelledby="countDropdown"
            >
              <a className="dropdown-item py-3">
                <p className="mb-0 font-weight-medium float-left">
                  You have 3 unread mails
                </p>
                <span className="badge badge-pill badge-primary float-right">
                  View all
                </span>
              </a>
              <div className="dropdown-divider"></div>
              <a className="dropdown-item preview-item">
                <div className="preview-thumbnail">
                  <img
                    src="images/faces/face10.jpg"
                    alt="image"
                    className="img-sm profile-pic"
                  />
                </div>
                <div className="preview-item-content flex-grow py-2">
                  <p className="preview-subject ellipsis font-weight-medium text-dark">
                    message 1
                  </p>
                  <p className="fw-light small-text mb-0"> sample message 1 </p>
                </div>
              </a>
              <a className="dropdown-item preview-item">
                <div className="preview-thumbnail">
                  <img
                    src="images/faces/face12.jpg"
                    alt="image"
                    className="img-sm profile-pic"
                  />
                </div>
                <div className="preview-item-content flex-grow py-2">
                  <p className="preview-subject ellipsis font-weight-medium text-dark">
                    message 2
                  </p>
                  <p className="fw-light small-text mb-0"> sample message 2 </p>
                </div>
              </a>
              <a className="dropdown-item preview-item">
                <div className="preview-thumbnail">
                  <img
                    src="images/faces/face1.jpg"
                    alt="image"
                    className="img-sm profile-pic"
                  />
                </div>
                <div className="preview-item-content flex-grow py-2">
                  <p className="preview-subject ellipsis font-weight-medium text-dark">
                    message 3
                  </p>
                  <p className="fw-light small-text mb-0"> sample message 3 </p>
                </div>
              </a>
            </div>
          </li>
          <li className="nav-item dropdown d-none d-lg-block user-dropdown">
            <a
              className="nav-link"
              id="UserDropdown"
              href="#"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img
                className="img-xs rounded-circle"
                src={userimg}
                alt="Profile image"
              />
            </a>
            <div
              className="dropdown-menu dropdown-menu-right navbar-dropdown"
              aria-labelledby="UserDropdown"
            >
              <div className="dropdown-header text-center">
                <img
                  className="img-md rounded-circle"
                  src="images/faces/face8.jpg"
                  alt="Profile image"
                />
                <p className="mb-1 mt-3 font-weight-semibold">{name}</p>
                <p className="fw-light text-muted mb-0">{mail}</p>
              </div>
              <a className="dropdown-item">
                <i className="dropdown-item-icon mdi mdi-account-outline text-primary me-2"></i>
                My Profile
              </a>
              <a className="dropdown-item">
                <i className="dropdown-item-icon mdi mdi-message-text-outline text-primary me-2"></i>
                Messages
              </a>
              <a className="dropdown-item">
                <i className="dropdown-item-icon mdi mdi-calendar-check-outline text-primary me-2"></i>
                Activity
              </a>
              <a className="dropdown-item">
                <i className="dropdown-item-icon mdi mdi-help-circle-outline text-primary me-2"></i>
                FAQ
              </a>
              <a className="dropdown-item" onClick={logoutfunc}>
                <i className="dropdown-item-icon mdi mdi-power text-primary me-2"></i>
                Sign Out
              </a>
            </div>
          </li>
        </ul>
        <button
          className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
          type="button"
          data-bs-toggle="offcanvas"
        >
          <span className="mdi mdi-menu"></span>
        </button>
      </div>
      <ToastContainer />
    </nav>
  );
};

export default NavBar;
