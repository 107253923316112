import React, { useContext, useState } from 'react';
import { NavLink } from 'react-router-dom';
import "../App.css";
// import { useNavigate } from 'react-router-dom';
import ApiContext from '../Context/ApiContext';

const SideBar = () => {
    // State to manage the visibility of sub-menus
    const [openMenu, setOpenMenu] = useState(null);
    // const navigate = useNavigate();

    // Function to toggle the visibility of sub-menus
    const toggleMenu = (menuId) => {
        setOpenMenu(openMenu === menuId ? null : menuId);
    };

    const {logoutfunc} = useContext(ApiContext)
    

    return (
        <nav className="sidebar sidebar-offcanvas" id="sidebar">
            <ul className="nav">
                {/* Dashboard */}
                <li className="nav-item">
                    <NavLink className="nav-link" to="/auth/home">
                        <div onClick={() => toggleMenu('dashboard')}>
                            <i className="mdi mdi-grid-large menu-icon"></i>
                            <span className="menu-title textBold">ITI/ Polytecnic</span>
                        </div>
                    </NavLink>
                </li>
                <li className="nav-item">
                    <NavLink className="nav-link" to="/auth/igcsm">
                        <div onClick={() => toggleMenu('dashboard')}>
                            <i className="mdi mdi-grid-large menu-icon"></i>
                            <span className="menu-title textBold">IGCSM Franchisee</span>
                        </div>
                    </NavLink>
                </li>
                <li className="nav-item">
                    <NavLink className="nav-link" to="/auth/sgtu">
                        <div onClick={() => toggleMenu('dashboard')}>
                            <i className="mdi mdi-grid-large menu-icon"></i>
                            <span className="menu-title textBold">SGTU Enquiry</span>
                        </div>
                    </NavLink>
                </li>

                {/* Analytics */}
                {/* <li className="nav-item">
                    <NavLink className="nav-link" to="/auth/analytics">
                        <div onClick={() => toggleMenu('analytics')}>
                            <i className="menu-icon mdi mdi-chart-line"></i>
                            <span className="menu-title textBold">Analytics</span>
                        </div>
                    </NavLink>
                </li> */}

                {/* My Assets */}
                {/* <li className="nav-item">
                    <NavLink className="nav-link" to="/auth/myassets">
                        <i className="mdi mdi-database-plus menu-icon"></i>
                        <span className="menu-title textBold">Inventory</span>
                    </NavLink>
                </li> */}

                {/* Monitoring */}
                {/* <li className="nav-item">
                    <NavLink className="nav-link" to="/auth/monitoring">
                        <i className="mdi mdi-access-point-network menu-icon"></i>
                        <span className="menu-title textBold">Monitoring</span>
                    </NavLink>
                </li> */}

                {/* Ticket Summary */}
                {/* <li className="nav-item">
                    <NavLink className="nav-link" to="/auth/ticket-summary">
                        <i className="mdi mdi-note-text menu-icon"></i>
                        <span className="menu-title textBold">Ticket Summary</span>
                    </NavLink>
                </li> */}

                {/* Reports */}
                {/* <li className="nav-item">
                    <div className="nav-link" onClick={() => toggleMenu('reports')}>
                        <i className="menu-icon mdi mdi-office"></i>
                        <span className="menu-title textBold">Reports</span>
                        <i className={`menu-arrow ${openMenu === 'reports' ? 'active' : ''}`}></i>
                    </div>
                    <div className={`collapse ${openMenu === 'reports' ? 'show' : ''}`} id="Reports">
                        <ul className="nav flex-column sub-menu">
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/auth/dailyreport">Daily Reports</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/auth/monthlyreport">Monthly Reports</NavLink>
                            </li>
                        </ul>
                    </div>
                </li> */}

                {/* Library */}
                {/* <li className="nav-item">
                    <div className="nav-link" onClick={() => toggleMenu('library')}>
                        <i className="menu-icon mdi mdi-library"></i>
                        <span className="menu-title textBold">Library</span>
                        <i className={`menu-arrow ${openMenu === 'library' ? 'active' : ''}`}></i>
                    </div>
                    <div className={`collapse ${openMenu === 'library' ? 'show' : ''}`} id="Library">
                        <ul className="nav flex-column sub-menu">
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/auth/contact">Contact List</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/auth/matrix">Escalation Matrix</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/auth/knowledge">KnowledgeBase</NavLink>
                            </li>
                        </ul>
                    </div>
                </li> */}

                {/* Customization */}
                {/* <li className="nav-item">
                    <div className="nav-link" onClick={() => toggleMenu('customization')}>
                        <i className="menu-icon mdi mdi-wrench"></i>
                        <span className="menu-title textBold">Customization</span>
                        <i className={`menu-arrow ${openMenu === 'customization' ? 'active' : ''}`}></i>
                    </div>
                    <div className={`collapse ${openMenu === 'customization' ? 'show' : ''}`} id="customization">
                        <ul className="nav flex-column sub-menu">
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/auth/reportmanager">Report Manager</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/auth/notification">Notification</NavLink>
                            </li>
                        </ul>
                    </div>
                </li> */}

                {/* Users */}
                {/* <li className="nav-item">
                    <div className="nav-link" onClick={() => toggleMenu('user')}>
                        <i className="menu-icon mdi mdi-account-circle-outline"></i>
                        <span className="menu-title textBold">Users</span>
                        <i className={`menu-arrow ${openMenu === 'user' ? 'active' : ''}`}></i>
                    </div>
                    <div className={`collapse ${openMenu === 'user' ? 'show' : ''}`} id="user">
                        <ul className="nav flex-column sub-menu">
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/auth/user">Add User</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/auth/departments">Departments</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/auth/problemcode">Problem Code</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" to="#">Service RFO</NavLink>
                            </li>
                        </ul>
                    </div>
                </li> */}

                {/* Sign Out */}
                <li className="nav-item">
                    <span className="nav-link" onClick={logoutfunc}>
                        <i className="mdi mdi-power menu-icon"></i>
                        <span className="menu-title textBold">SIGN OUT</span>
                    </span>
                </li>
            </ul>
        </nav>
    )
}

export default SideBar;
