import React from 'react';
import logo from "../Images/igcsmlogo.webp";
import {Link} from 'react-router-dom'

const LandingPage = () => {
    return (
        <>
            <main className="flex-shrink-0">
                <nav className="navbar navbar-expand-lg navbar-light bg-white py-3">
                    <div className="container px-5">
                        <img src={logo} alt="" className="w-25" />
                        <div className="" id="navbarSupportedContent">
                            <ul className="navbar-nav ms-auto mb-2 mb-lg-0 small fw-bolder">
                                <Link to="/login"><button type="button" className="btn btn-primary"> Login </button></Link>
                            </ul>
                        </div>
                    </div>
                </nav>
                {/* Header */}
                <header className="py-5">
                    <div className="container px-5 pb-5">
                        <div className="row gx-5 align-items-center">
                            <div className="">
                                <div className="text-center ">
                                    <div className="badge bg-gradient-primary-to-secondary text-white mb-4"></div>
                                    <div className="fs-3 fw-light text-muted">Login to your account</div>
                                    <h1 className="display-3 fw-bolder mb-5"><span className="text-gradient d-inline">Login here to get details about Enquiry</span></h1>
                                    <div className="d-grid gap-3 d-sm-flex justify-content-sm-center  mb-3">
                                        <a className="btn btn-primary btn-lg px-5 py-3 me-sm-3 fs-6 fw-bolder" href="#">Trial</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
            </main>
        </>
    );
}

export default LandingPage;
