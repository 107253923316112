import React from 'react';
import { Routes, Route} from 'react-router-dom';
import NavBar from './Components/NavBar';
import SideBar from './Components/SideBar';
import DashBoard from './Components/DashBoard';
import Overview from './Components/Overview';
import Login from './Pages/Login';
import LandingPage from './Pages/LandingPage';
import AddAssets from './Components/Forms/AddAssets';
import AddUser from './Components/Forms/AddUser';
import MyAssets from './Pages/MyAssets';
import User from './Pages/User';
import './assets/Style.css';
import './assets/feather/feather.css';
import './assets/mdi/css/materialdesignicons.min.css';
import './assets/ti-icons/css/themify-icons.css';
import './assets/typicons/typicons.css';
import './assets/simple-line-icons/css/simple-line-icons.css';
import './assets/vendor.bundle.base.css';
import Monitering from './Components/Monitering';
import ReportManager from './Components/ReportManager';
import Notification from './Components/Notification';
import Analytics from './Pages/Analytics';
import Ticket from './Pages/Ticket';
import './assets/template'
import AlertState from './Context/AlertState';
import ApiState from './Context/ApiState';
import EditUser from './Components/Forms/EditUser';
import EditAsset from './Components/Forms/EditAsset';
import Ping from './Components/Forms/Ping';
import ViewAsset from './Components/Forms/ViewAsset';
import UpdateTicket from './Components/Forms/UpdateTicket';
import Protected from './Pages/Protected'
import EsclationMatrix from './Components/EsclationMatrix'
import './assets/vendor.bundle.base'
import DailyReport from './Components/DailyReport';
import MonthlyReport from './Components/MonthlyReport';
import KnowledgeBase from './Components/KnowledgeBase';
import ContactList from './Components/ContactList';
import Department from './Components/Department';
import ProblemCode from './Components/ProblemCode';
import Servicerfo from './Components/Servicerfo'
import { SettingsInputHdmiTwoTone } from '@mui/icons-material';
import Home from './Components/Home';
import Igcsm from './Components/Igcsm';
import Sgtu from './Components/Sgtu';




function App() {


  const nestedroute = [
    {
      path: 'home',
      element: <Home/>
      // element: <DashBoard/>
    },
    {
      path: 'igcsm',
      element: <Igcsm/>
      // element: <DashBoard/>
    },
    {
      path: 'sgtu',
      element: <Sgtu/>
      // element: <DashBoard/>
    },
    // {
    //   path: 'AddAssets',
    //   element: <AddAssets/>
    // },
    // {
    //   path: 'adduser',
    //   element: <AddUser/>
    // },
    // {
    //   path: 'user',
    //   element: <User/>
    // },
    // {
    //   path: 'myassets',
    //   element: <MyAssets/>
    // },
    // {
    //   path: 'Analysis',
    //   element: <Overview/>
    // },
    // {
    //   path: 'monitoring',
    //   element: <Monitering/>
    // },
    // {
    //   path: 'reportmanager',
    //   element: <ReportManager/>
    // },
    // {
    //   path: 'notification',
    //   element: <Notification />
    // },
    // {
    //   path: 'analytics',
    //   element: <Analytics />
    // },
    // {
    //   path: 'ticket-summary',
    //   element: <Ticket />
    // },
    // {
    //   path: 'edituser',
    //   element: <EditUser />
    // },
    // {
    //   path: 'viewasset',
    //   element: <ViewAsset />
    // },
    // {
    //   path: 'editassets',
    //   element: <EditAsset />
    // },
    // {
    //   path: 'ping',
    //   element: <Ping />
    // },
    // {
    //   path: 'updateticket',
    //   element: <UpdateTicket />
    // },
    // {
    //   path: 'ping',
    //   element: <Ping />
    // },
    // {
    //   path: 'dailyreport',
    //   element: <DailyReport/>
    // },
    // {
    //   path: 'monthlyreport',
    //   element: <MonthlyReport/>
    // },
    // {
    //   path: 'matrix',
    //   element: <EsclationMatrix/>
    // },
    // {
    //   path: 'knowledge',
    //   element: <KnowledgeBase/>
    // },
    // {
    //   path: 'contact',
    //   element: <ContactList/>
    // },
    // {
    //   path: 'departments',
    //   element: <Department/>
    // },
    // {
    //   path: 'problemcode',
    //   element: <ProblemCode/>
    // },
    // {
    //   path: 'servicerfo',
    //   element: <Servicerfo/>
    // }
  ]


  return (
    <AlertState>
      <ApiState>

        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route
            path="/login"
            element={
              <Login/>
            }
          />

          <Route path="*" element={<LandingPage/>}/>
          <Route
            path="/auth"
            element={<Protected />}
          >
            {nestedroute.map((data,index) => (
              <Route
                key={index}
                path={data.path}
                element={
                  <>
                    <NavBar />
                    <div className="d-flex" id="rohan">
                      <SideBar />
                      {data.element}
                    </div>
                  </>
                }
              />
            ))}
          </Route>
          
        </Routes>
      </ApiState>

    </AlertState>
  );
}

export default App;
