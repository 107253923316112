import { useState, useContext, useEffect } from 'react';
import Swal from 'sweetalert2';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ApiContext from '../../Context/ApiContext';

function EditAsset() {
  const navigate = useNavigate();
  const { url, editAssetData, fetchAssetData } = useContext(ApiContext);

  const [formData, setFormData] = useState({
    Link_ID: '',
    Site_Name: '',
    Address: '',
    Connectivity: '',
    Link_BW: '',
    IP_Address: '',
    IP_Address2: '',
    Email_Id: '',
    Serial_No: '',
    Discovered: '',
    Model: '',
    Project: ''
  });

  const resetFormData = () => {
    setFormData({
      Link_ID: '',
      Site_Name: '',
      Address: '',
      Connectivity: '',
      Link_BW: '',
      IP_Address: '',
      IP_Address2: '',
      Email_Id: '',
      Serial_No: '',
      Discovered: '',
      Model: '',
      Project: ''
    });
  };

  useEffect(() => {
    if (editAssetData) {
      setFormData({
        Link_ID: editAssetData.linkId,
        Site_Name: editAssetData.siteName,
        Address: editAssetData.address,
        Connectivity: editAssetData.connectivity,
        Link_BW: editAssetData.linkBW,
        IP_Address: editAssetData.ipAddress1,
        IP_Address2: editAssetData.ipAddress2,
        Email_Id: editAssetData.emailId,
        Serial_No: editAssetData.serialNo,
        Discovered: editAssetData.discoveryDate,
        Model: editAssetData.modelMake,
        Project: editAssetData.projectName
      });
    }
  }, [editAssetData]);

  const [existingLinkIDs, setExistingLinkIDs] = useState([]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let isValid = true;

    // if (name === 'Link_ID') {
    //   if (existingLinkIDs.includes(value)) {
    //     isValid = false;
    //     Swal.fire({
    //       title: 'Error!',
    //       text: 'Asset with the same Link_ID already exists',
    //       icon: 'error',
    //     });
    //   }
    // }

    if (isValid) {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleSubmit = async () => {
    const isAnyFieldEmpty = Object.values(formData).some((value) => value === '');

    if (isAnyFieldEmpty) {
      Swal.fire({
        title: 'Error!',
        text: 'All fields are required',
        icon: 'error',
      });
      return;
    }

    const formattedData = {
      siteName: formData.Site_Name,
      address: formData.Address,
      modelMake: formData.Model,
      serialNo: formData.Serial_No,
      ipAddress1: formData.IP_Address,
      ipAddress2: formData.IP_Address2,
      connectivity: formData.Connectivity,
      linkBW: formData.Link_BW,
      discoveryDate: formData.Discovered,
      emailId: formData.Email_Id,
      projectName: formData.Project
    };

    try {
      
    console.log(url);
      const response = await fetch(`${url}/api/assets/${editAssetData.linkId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'token': sessionStorage.getItem('token')
        },
        body: JSON.stringify(formattedData),
      });

      if (response.ok) {
        Swal.fire({
          title: 'Success!',
          text: 'Asset Updated Successfully',
          icon: 'success',
        }).then((result) => {
          if (result.isConfirmed) {
            fetchAssetData();
            resetFormData();
            navigate('/auth/myassets'); // Navigate back to assets list after update
          }
        });
      } else {
        const errorText = await response.text();
        Swal.fire({
          title: 'Error!',
          text: `Asset Update Failed: ${errorText}`,
          icon: 'error',
        });
      }
    } catch (error) {
      Swal.fire({
        title: 'Error!',
        text: `Error Updating Asset: ${error.message}`,
        icon: 'error',
      });
    }
  };

  const handleDelete = async () => {

    try {
      const response = await fetch(`${url}/api/assets/${editAssetData.linkId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'token': sessionStorage.getItem('token')
        },
      });

      if (response.ok) {
        fetchAssetData()
        Swal.fire({
          title: 'Success!',
          text: 'Deleted successfully',
          icon: 'success',
        });

        setTimeout(()=>{
          navigate('/auth/myassets')
        },1000)
      } else {
        Swal.fire({
          title: 'Error!',
          text: 'Form submission failed',
          icon: 'error',
        });
      }
    } catch (error) {
      Swal.fire({
        title: 'Error!',
        text: 'Error submitting form',
        icon: 'error',
      });
    }
  };

  return (
    <>
      <div className="main-panel">
        <div className="content-wrapper">
          <form className='pt-4'>
            <div className="row mb-3">
              <div className="col">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Link ID"
                  name="Link_ID"
                  value={formData.Link_ID}
                  onChange={handleInputChange}
                  disabled
                />
              </div>
              <div className="col">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Site Name"
                  name="Site_Name"
                  value={formData.Site_Name}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="col">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Address"
                  name="Address"
                  value={formData.Address}
                  onChange={handleInputChange}
                  required
                />
              </div>
            </div>

            <div className="row mb-3">
              <div className="col">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Model/Make"
                  name="Model"
                  value={formData.Model}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="col">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Serial No"
                  name="Serial_No"
                  value={formData.Serial_No}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="col">
                <input
                  type="text"
                  className="form-control"
                  placeholder="IP Address-1"
                  name="IP_Address"
                  value={formData.IP_Address}
                  onChange={handleInputChange}
                  required
                />
              </div>
            </div>

            <div className="row mb-3">
              <div className="col">
                <input
                  type="text"
                  className="form-control"
                  placeholder="IP Address-2"
                  name="IP_Address2"
                  value={formData.IP_Address2}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="col">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Connectivity"
                  name="Connectivity"
                  value={formData.Connectivity}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="col">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Link BW"
                  name="Link_BW"
                  value={formData.Link_BW}
                  onChange={handleInputChange}
                  required
                />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Discovered Date"
                  name="Discovered"
                  value={formData.Discovered}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="col">
                <input
                  type="text"
                  className="form-control"
                  placeholder="E-mail Id"
                  name="Email_Id"
                  value={formData.Email_Id}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="col">
              <input
                      type="text"
                      className="form-control"
                      placeholder="Project Name"
                      name="Project"
                      value={formData.Project}
                      onChange={handleInputChange}
                      required
                    />
              </div>
            </div>

            <div className="row mb-3">
              <div className="col">
                <Button variant="contained" onClick={handleSubmit} sx={{ mx: '2px' }} >Update</Button>

                <Button variant="contained" onClick={handleDelete} sx={{ mx: '20px' }} >Delete</Button>
              </div>
              <div className="col">
              </div>
              <div className="col d-flex justify-content-center">
                <Button variant="contained" onClick={() => { navigate('/auth/myassets') }}>Back</Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default EditAsset;
