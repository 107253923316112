import React from 'react'
import EnquiryTable from './Tables/SgtuTable'


const Sgtu = () => {
  return (
    <div className="main-panel">
      <div className="content-wrapper">
        <div className="heading m-2">
          <h2>Enquiry Form Data - SGTU</h2>
        </div>
        <div className="container mt-4">
          <EnquiryTable />
        </div>
      </div>
    </div>
  );
}

export default Sgtu