document.addEventListener('DOMContentLoaded', function() {
  var body = document.body;
  var sidebar = document.querySelector('.sidebar');
  var navItems = document.querySelectorAll('.nav li a');
  var horizontalMenuItems = document.querySelectorAll('.horizontal-menu .nav li a');
  var horizontalMenu = document.querySelector('.horizontal-menu');
  var horizontalMenuNavItems = document.querySelectorAll('.horizontal-menu .page-navigation > .nav-item');

  // console.log('DOM fully loaded and parsed');

  function addActiveClass(element) {
    var current = window.location.pathname.split("/").slice(-1)[0].replace(/^\/|\/$/g, '');
    if (current === "") {
      if (element.getAttribute('href').indexOf("index.html") !== -1) {
        var navItem = element.closest('.nav-item');
        if (navItem) navItem.classList.add('active');
        var subMenu = element.closest('.sub-menu');
        if (subMenu) {
          var collapse = subMenu.closest('.collapse');
          if (collapse) collapse.classList.add('show');
          element.classList.add('active');
        }
      }
    } else {
      if (element.getAttribute('href').indexOf(current) !== -1) {
        var navItem = element.closest('.nav-item');
        if (navItem) navItem.classList.add('active');
        var subMenu = element.closest('.sub-menu');
        if (subMenu) {
          var collapse = subMenu.closest('.collapse');
          if (collapse) collapse.classList.add('show');
          element.classList.add('active');
        }
        var submenuItem = element.closest('.submenu-item');
        if (submenuItem) submenuItem.classList.add('active');
      }
    }
  }

  if (navItems) {
    navItems.forEach(function(item) {
      addActiveClass(item);
    });
  }

  if (horizontalMenuItems) {
    horizontalMenuItems.forEach(function(item) {
      addActiveClass(item);
    });
  }

  if (sidebar) {
    sidebar.addEventListener('show.bs.collapse', function(event) {
      var openSubmenu = sidebar.querySelector('.collapse.show');
      if (openSubmenu) {
        openSubmenu.classList.remove('show');
      }
    });
  }

  var minimizeButton = document.querySelector('[data-bs-toggle="minimize"]');
  if (minimizeButton) {
    minimizeButton.addEventListener("click", function() {
      if (body.classList.contains('sidebar-toggle-display') || body.classList.contains('sidebar-absolute')) {
        body.classList.toggle('sidebar-hidden');
      } else {
        body.classList.toggle('sidebar-icon-only');
      }
    });
  }

  var horizontalMenuToggle = document.querySelector('[data-toggle="horizontal-menu-toggle"]');
  if (horizontalMenuToggle) {
    horizontalMenuToggle.addEventListener("click", function() {
      var bottomNavbar = document.querySelector(".horizontal-menu .bottom-navbar");
      if (bottomNavbar) {
        bottomNavbar.classList.toggle("header-toggled");
      }
    });
  }

  if (horizontalMenuNavItems) {
    horizontalMenuNavItems.forEach(function(item) {
      item.addEventListener("click", function() {
        if (window.matchMedia('(max-width: 991px)').matches) {
          if (!item.classList.contains('show-submenu')) {
            horizontalMenuNavItems.forEach(function(navItem) {
              navItem.classList.remove('show-submenu');
            });
          }
          item.classList.toggle('show-submenu');
        }
      });
    });
  }

  if (window) {
    window.addEventListener('scroll', function() {
      if (window.matchMedia('(min-width: 992px)').matches) {
        if (window.scrollY >= 70) {
          if (horizontalMenu) horizontalMenu.classList.add('fixed-on-scroll');
        } else {
          if (horizontalMenu) horizontalMenu.classList.remove('fixed-on-scroll');
        }
      }
    });
  }

  var searchIcon = document.getElementById('navbar-search-icon');
  if (searchIcon) {
    searchIcon.addEventListener('click', function() {
      var searchInput = document.getElementById('navbar-search-input');
      if (searchInput) {
        searchInput.focus();
      }
    });
  }
});
